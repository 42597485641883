import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Welcome from './components/Welcome';
import Dials from '../../person/overview/components/Dials';
import OlerexPlus from '../../person/overview/components/OlerexPlus';
import FuelPrices from './components/FuelPrices';
import Bills from './components/Bills';
import Cards from './components/Cards';
import Loader from '../../components/Loader';
import { roleOrLanguageChanged } from '../../../../Utils';
import { url, businessDashboardRequest } from '../../../../Networking';
import './Overview.scss';
import { roleChange } from '../../../../state/Login';

const mapDiscountBlockText = text => text && ({
    text: text.text,
    bold: text.isBold,
});

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaderVisible: true,
            name: null,
            balance: null,
            creditLimit: null,
            info: null,
            newestCards: null,
            lastInvoices: null,
            todaysPrices: null,
            notifications: []
        };
    }

    downloadPDf = (invoiceNumber, isLink, pdfUrl) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
    if (isLink && pdfUrl) {
            window.open(pdfUrl);
        } else {
            window.open(`${url}&act=olerexweb.business.invoices&requestType=invoicePdf&token=${this.props.login.token}&accNr=${accnr}&invoiceNo=${invoiceNumber}&pdf=1`);
        }
    }
    downloadPricesHistoryExcel = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        window.open(encodeURI(`${url}&act=olerexweb.business.dashboard&token=${this.props.login.token}&accNr=${accnr}&pricesHistoryExcel=1`));
    }
    downloadData = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in private role
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 5000));
            this.props.dashboardRequest(
                {
                    "accNr": accnr,
                    "token": this.props.login.token
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }

                    // If self service blocked - change role to private client
                    if(data.records.isSelfServiceBlocked === '1') {
                        const privateClientData = {
                            ...this.props.login.privateClientData,
                            secondary: 'Eraisik',
                            img: '/static/media/Icon_Role-Eraisik_48px.svg'
                        };
                        this.props.roleChange(privateClientData);
                        return;
                    }

                    data.records.lastInvoices = data.records.lastInvoices.map(x => ({ ...x, onDownloadButtonClick: () => this.downloadPDf(x.number, x.isLink, x.pdfUrl) }));
                    data.records.infoBubble2.fill = Math.round(360 * parseInt(data.records.infoBubble2.value.substring(0, data.records.infoBubble2.value.length, -1)) / 100);

                    let loyaltyProgress = null;
                    let loyaltyInfo = null;

                    if (data.records.discountBlock) {
                        loyaltyProgress = data.records.discountBlock.discounts.map(block => ({
                            thumbnail: block.image,
                            name: mapDiscountBlockText(block.top_txt),
                            bottom: mapDiscountBlockText(block.btm_txt),
                        }));

                        loyaltyInfo = data.records.discountBlock && {
                            loyaltyTitle: data.records.discountBlock.title,
                            loyaltyText: data.records.discountBlock.tooltip || null,
                        };
                    }

                    this.setState({
                        ...data.records,
                        isLoaderVisible: false,
                        loyaltyProgress,
                        loyaltyInfo,
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    }

    onUserDataUpdated = () => {
        this.downloadData();
    }

    onShowAllDiscounts = () => {
        this.props.onOlerexPlusModalOpen({
            onShowAllClose: this.onShowAllDiscountsClose,
            loyaltyProgress: this.state.loyaltyProgress,
            loyaltyInfo: this.state.loyaltyInfo,
        });
    }

    onShowAllDiscountsClose = () => {
        this.props.onOlerexPlusModalClose();
    }

    render() {
        const footer = this.state.footerAdvertisement;
        const footerColor = (footer && footer.backgroundHex) || "#000000";
        const footerImg = footer && footer.image;
        const footerUrl = footer && footer.imageUrl;

        const hasInvoices = this.state.isLoaderVisible || (this.state.lastInvoices && this.state.lastInvoices.length > 0);
        const hasCards = this.state.isLoaderVisible || (this.state.newestCards && this.state.newestCards.length > 0);

        return (
            <div className="business-overview">
                <div className="business-overview-header-shadow"></div>
                <div className="business-overview-header">
                    <TransitionGroup component={null}>
                        <CSSTransition key={"welcome-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade welcome-text"}>
                            <Welcome {...{
                                welcomeText: this.state.welcomeText,
                                expiringCards: this.state.expiringCards,
                                unpaidInvoices: this.state.unpaidInvoices,
                                navigateToInvoices: this.props.navigateToInvoices,
                                navigateToCards: this.props.navigateToCards,
                                notifications: this.state.notifications,
                                navigateTo: this.props.navigateTo
                            }} />
                        </CSSTransition>
                        {!this.state.isLoaderVisible &&
                            <CSSTransition key={"dials-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <Dials {...{
                                    infoBubble1: this.state.infoBubble1,
                                    infoBubble2: this.state.infoBubble2,
                                    infoBubble3: this.state.infoBubble3,
                                }} /></CSSTransition>}
                    </TransitionGroup>
                </div>
                <TransitionGroup component={null}>
                    {this.state.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"} in>
                        <Loader {...{ className: "business-overview-header-loader" }} />
                    </CSSTransition>}
                </TransitionGroup>
                <div className="business-overview-body">
                    <FuelPrices {...{
                        todaysPrices: this.state.todaysPrices,
                        isLoaderVisible: this.state.isLoaderVisible,
                        downloadTodaysPrices: this.downloadPricesHistoryExcel,
                        askPriceConfirmationTxt: this.state.askPriceConfirmationTxt,
                        onModalOpen: this.props.onModalOpen,
                        onModalClose: this.props.onModalClose,
                        businessDashboardRequest: this.props.dashboardRequest,
                        login: this.props.login
                    }} />
                    {this.state.discountBlock && <OlerexPlus {...{
                        className: 'olerex-plus-business',
                        itemsPerRow: 4,
                        onShowAllToggle: () => this.onShowAllDiscounts(),
                        loyaltyProgress: this.state.loyaltyProgress,
                        loyaltyInfo: this.state.loyaltyInfo,
                        isLoaderVisible: this.state.isLoaderVisible,
                    }} />}
                    {hasInvoices && (
                        <Bills {...{
                            lastInvoices: this.state.lastInvoices,
                            isLoaderVisible: this.state.isLoaderVisible,
                            navigateToInvoices: this.props.navigateToInvoices,
                            onModalOpen: this.props.onModalOpen,
                            onModalClose: this.props.onModalClose
                        }} />
                    )}
                    {hasCards && (
                        <Cards {...{
                            newestCards: this.state.newestCards,
                            isLoaderVisible: this.state.isLoaderVisible,
                            navigateToCards: this.props.navigateToCards
                        }} />
                    )}
                </div>
                <div className="business-overview-footer-shadow"></div>
                <div className="business-overview-ad-background" style={{ background: footerColor }}></div>
                <div className="business-overview-ad">
                    <a href={footerUrl} target="_blank" rel="noopener noreferrer">
                        <img className="business-overview-ad_img" src={footerImg} alt="advertisement" />
                    </a>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        router: state.router,
        login: state.login
    }),
    dispatch => ({
        navigateToInvoices: () => dispatch(push('/invoicing/invoices')),
        navigateToCards: () => dispatch(push('/cards')),
        navigateTo: (path) => dispatch(push(path)),
        dashboardRequest: (body, success, error) => businessDashboardRequest(dispatch, body, success, error),
        roleChange: (data) => dispatch(roleChange(data))
    }))(Overview);
